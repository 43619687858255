import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Product} from '../Product';

@Component({
  selector: 'app-product-dialog',
  templateUrl: './ProductDialog.component.html',
  styleUrls: ['./ProductDialog.component.scss']
})
export class ProductDialogComponent implements OnInit {

  product: Product;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.product = data.product;
    }
  }

  ngOnInit(): void {
    console.log(this.product.name);
  }

}

export class Product {
  public id: string;
  public name: any;
  public description: string;
  public img: any;
  public price: any;
  public category: string;
  public subcategory: string;
  public brand: string;
  public type: string;
}

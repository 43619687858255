<h1 mat-dialog-title class="title"> {{product?.name}}</h1>
<div mat-dialog-content *ngIf="product" class="dialogContent">
  <img mat-card-image [src]='product.img' alt="{{product.name}}">
  <div class="productPrice">
    {{product?.price}}€
  </div>
  <div *ngIf="product?.description" class="descriptionContainer">
    <div><b>Descripción del producto: </b></div>
    <div class="descriptionContent">{{product?.description}}</div>
  </div>
</div>

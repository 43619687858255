<div class="toolbar" role="banner">
  <a href="https://frikilik.com"><img width="40" alt="Frikilik Logo" src="{{logo}}"/>
  </a>
  <div class="searchContainer">
    <mat-form-field *ngIf="filterForm" floatLabel="never" color="red">
      <input [formControl]="filterForm.controls[ 'nameFilter' ]" matInput placeholder=""
             (keyup)="onKey($event)">
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>
  </div>

</div>
<a href="https://api.whatsapp.com/send?phone=34675164187&text=Hola! Estoy interesado en un producto de su tienda."
   target="_blank" class="walink">
  <div>
    <span class="waspan">Hacer pedido</span>
    <img class="waimage" src="{{whatsapp}}">
    <span class="waspanphone">675 164 187</span>
  </div>

</a>
<div class="content" role="main">


  <div class="row" *ngIf="products.length">
    <div style="width: 100%;display: flex;">
      <button class="btn" (click)="showFilters()">
        <mat-icon>filter_list</mat-icon>
      </button>
      <pagination-template #p="paginationApi" [id]="paginationConfig.id" [maxSize]="maxSizePagination" autoHide="true"
                           (pageChange)="paginationConfig.currentPage = $event">

        <div class="totalHeader">
          <span>
            <span *ngIf="filteredProducts.length">
          <b>Mostrando: {{ (60 * (p.getCurrent() - 1)) + 1}}</b> - <b>{{(60 * (p.getCurrent())) > filteredProducts.length ? filteredProducts.length : (60 * (p.getCurrent()))}}</b>
          de </span><b>{{filteredProducts.length}}</b> resultados
          </span>
        </div>
      </pagination-template>
    </div>


    <div class="row full" *ngIf="showFilter">
      <form #form="ngForm" (ngSubmit)="filterProduct()" [formGroup]="filterForm" novalidate *ngIf="filterForm"
            class="webForm">
        <div class="fieldsContainer">

          <mat-form-field>
            <mat-label>Categorías</mat-label>
            <mat-select [formControl]="filterForm.controls[ 'categories' ]" multiple>
              <mat-option *ngFor="let category of categories" [value]="category"
                          (click)="onKey('CAT')">{{category}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Subcategorías</mat-label>
            <mat-select [formControl]="filterForm.controls[ 'subcategories' ]" multiple>
              <mat-option *ngFor="let subcategory of subcategories" [value]="subcategory"
                          (click)="onKey($event)">{{subcategory}}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Marcas</mat-label>
            <mat-select [formControl]="filterForm.controls[ 'brands' ]" multiple>
              <mat-option *ngFor="let brand of brands" [value]="brand" (click)="onKey($event)">{{brand}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
    </div>

    <div class="row">
      <mat-card class="product-card" *ngFor="let product of filteredProducts | paginate: paginationConfig"
                (click)="openDialog(product)">
        <img mat-card-image [src]='product.img' alt="{{product.name}}">
        <mat-card-content class="cardContent">
          <mat-card-title>{{product.name}}</mat-card-title>
        </mat-card-content>
        <mat-card-actions class="cardActions">
          <mat-card-subtitle>{{product.price}}€</mat-card-subtitle>
        </mat-card-actions>
      </mat-card>

    </div>

    <div class="col-12 mt-3 d-flex justify-content-center" *ngIf="filteredProducts.length > 60">
      <pagination-template #p="paginationApi" [id]="paginationConfig.id" [maxSize]="maxSizePagination" autoHide="true"
                           (pageChange)="paginationConfig.currentPage = $event">
        <nav aria-label="Page navigation example">
          <ul class="pagination">
            <li [className]="p.isFirstPage() ? 'page-item disabled' : 'page-item'">
              <a class="page-link" (click)="p.previous()" href="#" aria-label="Previous">
                <span aria-hidden="true">«</span>
              </a>
            </li>
            <li *ngFor="let page of p.pages"
                [className]=" p.getCurrent() === page.value ? 'page-item disabled current' : 'page-item'">
              <a class="page-link" href="#" (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                {{ page.label }}
              </a>
              <a class="page-link" href="#" *ngIf="p.getCurrent() === page.value">
                {{ page.label }}
              </a>
            </li>
            <li [className]=" p.isLastPage() ? 'page-item disabled' : 'page-item'">
              <a class="page-link" href="#" (click)="p.next()" aria-label="Next">
                <span aria-hidden="true">»</span>
              </a>
            </li>
          </ul>
        </nav>
        <div class="total">
          <span>
          <b>Mostrando: {{ (60 * (p.getCurrent() - 1)) + 1}}</b> - <b>{{(60 * (p.getCurrent())) > filteredProducts.length ? filteredProducts.length : (60 * (p.getCurrent()))}}</b>
          de <b>{{filteredProducts.length}}</b> resultados
          </span>
        </div>
      </pagination-template>
    </div>

    <div class="" *ngIf="!filteredProducts.length" style="width: 100%; display: block; text-align: center;">
      No existen productos que cumplan esta búsqueda
    </div>

  </div>
</div>
<router-outlet></router-outlet>

import {Component, OnInit, ViewChild} from '@angular/core';
import {Product} from './Product';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';
import {PaginationInstance} from 'ngx-pagination';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DeviceDetectorService} from 'ngx-device-detector';
import {MatDialog} from '@angular/material/dialog';
import {ProductDialogComponent} from './productDialog/productDialog.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(private http: HttpClient,
              private sanitizer: DomSanitizer, private formBuilder: FormBuilder, private deviceService: DeviceDetectorService, public dialog: MatDialog) {
  }

  maxSizePagination: string = '6';
  logo: string;
  whatsapp: string;
  products: Product[] = [];
  filteredProducts: Product[] = [];
  categories: string[] = [];
  subcategories: string[] = [];
  types: string[] = [];
  brands: string[] = [];
  nameFilter: string = '';
  showFilter: boolean = false;
  filterText: string = 'Mostrar filtros';

  filterForm: FormGroup;

  deviceInfo: any;

  isMobile: boolean;




  paginationConfig: PaginationInstance = {
    id: 'advanced',
    itemsPerPage: 60,
    currentPage: 1
  };

  @ViewChild('csvReader') csvReader: any;


  async ngOnInit() {
    // this.isMobile = this.deviceService.isMobile();
    this.logo = 'assets/frikilik_logo.png';
    this.whatsapp = 'assets/whatsApp.png';
    await this.loadProducts();
    await this.initForm();
  }

  openDialog(product: Product) {
    let maxWidth = '500px';
    if (this.isMobile) {
      maxWidth = '95vw';
    }
    this.dialog.open(ProductDialogComponent, {
      maxWidth: maxWidth,
      data: {
        product: product
      }
    });
  }


  getClass() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    // console.log(this.deviceInfo);
    // console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    // console.log(isDesktopDevice);
    if (!isMobile) {
      return 'hiddenElement';
    }
  }

  loadProducts() {
    let file = this.http.get('assets/catalog.csv',
      {
        responseType: 'text',
      }).subscribe(data => {
      let csvProductsArray = (<string> data).split(/\r\n|\n/);
      let headersRow = this.getHeaderArray(csvProductsArray);
      this.products = this.getDataProductsArrayFromCSVFile(csvProductsArray, headersRow.length);
      this.products = this.removeDuplicateProducts();
      this.filteredProducts = this.products;
    }, error => {
      console.log(error);
    });
  }

  getDataProductsArrayFromCSVFile(csvProductsArray: any, headerLength: any) {
    let products: Product[] = [];
    for (let i = 1; i < csvProductsArray.length; i++) {
      // for (let i = 1; i < 1000; i++) {
      let currentProduct = (<string> csvProductsArray[i]).split(';');

      if (currentProduct.length == headerLength && currentProduct[0] != '') {

        if (currentProduct[13].split('"').join('').trim() == '0') {
          continue;
        }
        if (currentProduct[24].split('"').join('').trim() != '1') {
          continue;
        }
        if (currentProduct[0].split('"').join('').trim() == '707') {
          continue;
        }

        let product: Product = new Product();
        product.id = currentProduct[0].split('"').join('').trim();
        product.name = currentProduct[3].split('"').join('').trim();
        product.description = currentProduct[5].split('"').join('').trim();
        product.img = this.sanitizer.bypassSecurityTrustResourceUrl(currentProduct[23].split('"').join('').trim());
        product.price = currentProduct[11].split('"').join('').trim();
        // product.category = this.setCategory(currentProduct[16].split('"').join('').trim());
        // product.subcategory = this.setSubcategory(currentProduct[16].split('"').join('').trim());
        product.type = this.setType(currentProduct[3].split('"').join('').trim());
        product.brand = this.setBrand(currentProduct[18].split('"').join('').trim());

        product.category = this.setCategory(this.getProductTypePath(currentProduct[46].split('"').join('').trim()));
        product.subcategory = this.setSubcategory(this.getProductTypePath(currentProduct[46].split('"').join('').trim()));

        products.push(product);
      }
    }
    this.categories.sort();
    this.subcategories.sort();
    this.types.sort();
    this.brands.sort();

    return products.sort(function(a, b) {
      let x = a.category.toLowerCase();
      let y = b.category.toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  getProductTypePath(category: string) {
    // console.log(category);
    /* <specific_data product_type_path="HIGIENE / PERFUMERIA|MASCARILLAS"></specific_data>*/
    category = category.replace('<specific_data product_type_path=', '');
    category = category.replace('></specific_data>', '');
    return category;
  }

  setCategory(category: string) {
    let simpleCategory = category.split('|')[0];
    simpleCategory = simpleCategory.toUpperCase();

    if (simpleCategory == 'STANDARD') {
      simpleCategory = 'MODA Y COMPLEMENTOS';
    }

    this.addCategoryToCategories(simpleCategory);
    return simpleCategory;
  }


  addCategoryToCategories(category: string) {
    if (this.categories.includes(category)) {
      return;
    }
    this.categories.push(category);
  }

  setSubcategory(category: string) {
    // console.log(category);
    let simpleSubcategory = category.split('|')[1];
    if (!simpleSubcategory) {
      simpleSubcategory = 'OTROS';

    }
    simpleSubcategory = simpleSubcategory.split('>')[0];
    // if ((simpleSubcategory == '')) {
    // }
    this.addSubcategoryToSubcategories(simpleSubcategory);
    return simpleSubcategory;
  }

  addSubcategoryToSubcategories(subcategory: string) {
    if (this.subcategories.includes(subcategory)) {
      return;
    }
    this.subcategories.push(subcategory);
  }

  setType(type: string) {
    let simpleType = type.split(' ')[0];
    if ((simpleType == 'Amigo') || (simpleType == 'Action')) {
      simpleType = 'Otros';
    }
    if ((simpleType == 'Set')) {
      simpleType = simpleType + ' ' + type.split(' ')[1];
    }
    simpleType = simpleType.toUpperCase();
    this.addTypeToTypes(simpleType);
    return simpleType;
  }

  addTypeToTypes(type: string) {
    if (this.types.includes(type)) {
      return;
    }
    this.types.push(type);
  }

  setBrand(brand: string) {
    let simpleBrand = brand.split('|')[0];
    if ((simpleBrand == 'OFERTAS') || (simpleBrand == 'BLACKFRIDAY') || (simpleBrand == 'BLACK WEEK')) {
      simpleBrand = 'OTROS';
    }
    this.addBrandToBrands(simpleBrand);
    return simpleBrand;
  }

  addBrandToBrands(brand: string) {
    if (this.brands.includes(brand)) {
      return;
    }
    this.brands.push(brand);
  }


  getHeaderArray(csvRecordsArr: any) {
    let headers = (<string> csvRecordsArr[0]).split(';');
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

  removeDuplicateProducts() {
    let dict = {};
    this.products = this.products.filter((current) => {
      let exists = !dict[current.name] || false;
      dict[current.name] = true;
      return exists;
    });

    dict = {};
    this.products = this.products.filter((current) => {
      if (current.name == null) {
        return true;
      }
      let exists = !dict[current.name] || false;
      dict[current.name] = true;
      return exists;
    });

    return this.products;
  }

  initForm() {
    this.filterForm = this.formBuilder.group({
      'categories': [''],
      'subcategories': [''],
      'types': [''],
      'nameFilter': [''],
      'brands': ['']
    });
  }


  onKey(e: string) {
    if (e == 'CAT') {
      console.log('entra');
      // this.filterForm['categories'] = [];
      this.filterForm.controls['subcategories'].reset();
    }
    this.filterProduct();
  }

  async filterProduct() {
    let categoriesFilter: string[] = this.filterForm.controls['categories'].value;
    let subcategoriesFilter = this.filterForm.controls['subcategories'].value;
    console.log(subcategoriesFilter);
    let typesFilter = this.filterForm.controls['types'].value;
    let brandsFilter = this.filterForm.controls['brands'].value;
    let nameFilter = this.filterForm.controls['nameFilter'].value;

    this.filteredProducts = this.products;
    /*
    AP
        <categories><category gesioid="1219" ref="emergency"><![CDATA[MARCAS|EMERGENCY]]></category></categories>
        <categories><category gesioid="1218" ref="mandalorian"><![CDATA[SERIE DE TV|MANDALORIAN]]></category><category gesioid="456" ref="PRE-PEDIDOS"><![CDATA[PRE-PEDIDOS]]></category><category gesioid="935" ref="pop"><![CDATA[FUNKO|POP!]]></category><category gesioid="708" ref="ARTICULOS COLECCION (POP CULTURE)"><![CDATA[POP CULTURE]]></category><category gesioid="589" ref="FUNKO"><![CDATA[FUNKO]]></category><category gesioid="121" ref="STAR WARS"><![CDATA[PELÍCULAS|STAR WARS]]></category></categories>


        AR
        https://www.ociostock.com/productos/imagenes/img_181800_82e8f1b7801aa7a340a1167bdc8278f5_1.jpg|https://www.ociostock.com/productos/imagenes/img_181801_0c3cf7b14f7664bcd5bd091bc56b213c_1.jpg|https://www.ociostock.com/productos/imagenes/img_181802_fca932ae48afaae8d8275bbffbc37031_1.jpg


        AU
        <specific_data product_type_path="HIGIENE / PERFUMERIA|MASCARILLAS"></specific_data>
        */

    if (categoriesFilter && categoriesFilter.length) {
      this.filteredProducts = await this.filteredProducts.filter((product) => {
        return categoriesFilter.includes(product.category);
      });
    }
    if (subcategoriesFilter && subcategoriesFilter.length) {
      this.filteredProducts = await this.filteredProducts.filter((product) => {
        return subcategoriesFilter.includes(product.subcategory);
      });
    }
    // if (typesFilter.length) {
    //   this.filteredProducts = await this.filteredProducts.filter((product) => {
    //     return typesFilter.includes(product.type);
    //   });
    // }
    if (brandsFilter && brandsFilter.length) {
      this.filteredProducts = await this.filteredProducts.filter((product) => {
        return brandsFilter.includes(product.brand);
      });
    }

    if (nameFilter) {
      this.filteredProducts = await this.filteredProducts.filter((product) => {
        let lowerNameFilter = nameFilter.toLowerCase();
        let productName = product.name.toLowerCase();
        let productBrand = product.brand.toLowerCase();
        return (productName.includes(lowerNameFilter) ||
          productBrand.includes(lowerNameFilter)
        );
      });
    }

    this.paginationConfig.currentPage = 1;

    this.setFiltercategory();
    this.setFilterSubcategory(categoriesFilter);
    this.setFilterBrand();
  }

  setFiltercategory() {
    this.categories = [];
    for (let filteredProduct of this.products) {
      this.addCategoryToCategories(filteredProduct.category);
    }
    this.categories.sort();
  }

  async setFilterSubcategory(categoriesFilter: string[]) {
    let products: Product[] = [];
    if (categoriesFilter && categoriesFilter.length) {
      products = await this.products.filter((product) => {
        return categoriesFilter.includes(product.subcategory);
      });
    }


    this.subcategories = [];
    for (let product of products) {
      this.addSubcategoryToSubcategories(product.subcategory);
    }


    this.subcategories.sort();
  }

  setFilterBrand() {
    this.brands = [];
    for (let filteredProduct of this.filteredProducts) {
      this.addBrandToBrands(filteredProduct.brand);
    }
    this.brands.sort();
  }

  showFilters() {
    this.showFilter = !this.showFilter;
    if (this.showFilter) {
      this.filterText = 'Ocultar filtros';
      return;
    }
    this.filterText = 'Mostrar filtros';

  }

}
